import React from "react"
import Layout from "../../layouts/Layout"
import { Header } from "../../components/Header"
import { Grids } from "../../components/Grids"
import { useSitePortfolioEn } from "../../hooks/use-site-portfolio-en"

const PortfolioPage = () => {
  const edges = useSitePortfolioEn()

  const metadata = {
    title: "Portfolio",
    description:
      "In this space I will show you my projects and tell you how I did them.In this space I will show you my projects and tell you how I did them. These projects deal with interface design and programming.",
    cover: "/images/covers/portfolio_cover-1.png",
    type: "website",
    path: "/portfolio",
  }

  return (
    <Layout metadata={metadata}>
      <Header
        title="Portfolio"
        content="I tell you about the journey of creation of each project, where I share my personal view of where I think some ideas can be taken, connecting creativity with innovation in technology and its practical use in everyday life."
      />

      <Grids edges={edges} />
    </Layout>
  )
}

export default PortfolioPage
