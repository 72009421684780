import { useStaticQuery, graphql } from "gatsby"

export const useSitePortfolioEn = () => {
  const data = useStaticQuery(
    graphql`
      query allPortfolioArticlesEn {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              language: { eq: "en" }
              category: { eq: "portfolio" }
            }
          }
          sort: { order: DESC, fields: frontmatter___date }
        ) {
          edges {
            node {
              frontmatter {
                slug
                imageCoverDesktop {
                  childImageSharp {
                    gatsbyImageData(layout: FIXED)
                  }
                }
                imageCoverMobile {
                  childImageSharp {
                    gatsbyImageData(layout: FIXED)
                  }
                }
                title_long
                title_short
              }
            }
          }
        }
      }
    `
  )
  return data.allMarkdownRemark.edges
}
